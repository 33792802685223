<template lang="pug">

	.products

		section.products__section.products__section--main

			img.products__img.products__img--main(src="~@/assets/img/products/Img_header-Products.jpg")

			.products__container(class="container")

				.products__content

					h1.products__title(class="primary-title") Products

					p.products__sub-title(class="sub-title") We offer a wide range of coatings to exceed your wildest expectations


		section.products__section.products__section--table

			img.products__img.products__img--table.products__img--table--1(src="~@/assets/img/products/img_products_yellow.svg")
			img.products__img.products__img--table.products__img--table--2(src="~@/assets/img/products/img_products_blue.svg")

			.products__container(class="container")


				.products__table(v-if="items.length")

					.products__table-row(class="u-df-between" v-for="(item, index) in items" :key="index")

						.products__table-number {{ item.number }}
						
						.products__table-name-box(class="u-df-start")
							.products__table-name.products__table-name--1(class="products-font") {{ item.name.firstPart }}
							.products__table-name.products__table-name--2(class="products-font" :style="{'color' : item.name.secondPart.color}") {{ item.name.secondPart.name }}

						.products__table-description
							.products__table-text {{ item.description.text }}

							.products__table-links(class="u-df-start" v-if="item.description.links.length")
								a.products__table-link(class="link link--blue" v-for="(link, innerIndex) in item.description.links" :key="innerIndex" :href="link.to" target="_blank") {{ link.name }}


		section.products__section.products__section--contact(class="u-df-center")

			img.products__img.products__img--contact(src="~@/assets/img/products/img_contact_us.jpg")
		
			.products__container.products__container--contact(class="container u-df-between")
				BaseContactFormComponent

</template>

<script>
export default {
	name: 'ProductsView',

	data() {
		return {
			items: [
				{
					number: '01',
					name: {
						firstPart: 'Titus',
						secondPart: {
							name: 'TPV',
							color: '#FC9802'
						}
					},
					description: {
						text: 'TitusTPV leads the charge when it comes to superior fall protection designed specifically for places where children climb and play. Our innovative and proprietary poured-in-place rubber product comes in a wide range of colors, eye-catching designs, and thicknesses that are customized for the project at hand. TitusTPV is a two-layer system made with the unique Rosehill TPV®Colored granules in the top layer and a base layer of recycled rubber. TitusTPV is a durable and long-lasting playground or recreational surface.',
						links: [
							// {
							// 	name: 'Brochure',
							// 	to: '/docs/TitusTPV_Brochure.pdf'
							// },
							// {
							// 	name: 'Technical Data',
							// 	to: '/docs/TitusTPV_Technical_data_sheet.pdf'
							// },
							// {
							// 	name: 'Specification',
							// 	to: '/docs/TitusTPV_Specification.docx'
							// },
							// {
							// 	name: 'Details',
							// 	to: '/docs/details.rar'
							// },
						]
					}
				},
				{
					number: '02',
					name: {
						firstPart: 'Titus',
						secondPart: {
							name: 'Trail',
							color: '#66B033'
						}
					},
					description: {
						text: 'TitusTrail is a single layer porous pavement product comprised of rubber granules, stone (optional), and a polyurethane binder. TitusTrail can be used in high traffic areas which require a durable, high traction, and porous pavement. TitusTrail is great for nature trails, porous sidewalks, drive ways, tree surrounds in cities, and any other porous pavement applications.',
						links: []
					}
				},
				{
					number: '03',
					name: {
						firstPart: 'Titus',
						secondPart: {
							name: 'Turf',
							color: '#66B033'
						}
					},
					description: {
						text: 'TitusTurf is a high quality synthetic turf which can be installed in a variety of places. It is a low maintenance product which provides the realistic appeal of a natural grass. It is the perfect solution for natural play areas, playgrounds, and more!',
						links: []
					}
				},
				{
					number: '04',
					name: {
						firstPart: 'Titus',
						secondPart: {
							name: 'EWF',
							color: '#A17C53'
						}
					},
					description: {
						text: 'TitusEWF is an engineered wood fiber product that is specially designed to be used around playgrounds and areas where fall protection is desired. It meets or exceeds all requirements of the Engineered Wood Fiber standard ASTM F2075-20. TitusEWF offers a low cost option to your playground fall protection requirements.',
						links: []
					}
				},
				{
					number: '05',
					name: {
						firstPart: '',
						secondPart: {
							name: 'GrassMats',
							color: '#333'
						}
					},
					description: {
						text: 'TitusTPV leads the charge when it comes to superior fall protection designed specifically for places where children climb and play. Our innovative and proprietary poured-in-place rubber product comes in a wide range of colors, eye-catching designs, and thicknesses that are customized for the project at hand. TitusTPV is a two-layer system made with the unique Rosehill TPV®Colored granules in the top layer and a base layer of recycled rubber. TitusTPV is a durable and long-lasting playground or recreational surface.',
						links: [
							{
								name: 'Grassmats Brochure',
								to: '/docs/Grassmats_Brochure.pdf'
							}
						]
					}
				},
        {
          number: '06',
          name: {
            firstPart: 'CAD',
            secondPart: {
              name: 'details',
              color: '#333'
            }
          },
          description: {
            text: 'Our Design Files are Live! Download high-quality CAD drawings, specifications, and related documents for your next project. The CADdetails program is developed specifically for design professionals with the goal of getting manufacturer-specific product information into their working plans.',
            links: [
              {
                name: 'View CADdetails',
                to: 'http://microsite.caddetails.com/5512'
              }
            ]
          }
        }
			]

		}

	}

}
</script>

<style scoped lang="sass">
@import '~@/assets/styles/abstracts/_variables.sass'


.products
	
	
	&__section


		&--main
			min-height: 67vh
			position: relative


			@media screen and (max-width: $phone)
				min-height: 51rem
			

		&--contact
			position: relative
			margin: 20rem 0 15rem


			@media screen and (max-width: $phone)
				margin-top: 0


	&__container


		&--contact


			@media screen and (max-width: $phone)				
				display: flex
				align-items: center
				justify-content: center
	
	
	&__img


		&--main
			object-fit: contain
			object-position: top

			position: absolute
			
			top: 0
			right: 0
			
			height: 93rem

			max-height: 95vh
			max-width: 60%

			z-index: -1


			@media screen and (max-width: $phone)
				max-height: 31rem
				max-width: 101%


		&--contact
			object-fit: contain
			object-position: right

			position: absolute
			
			top: -5rem
			right: 0

			height: 120%
			max-width: 50%

			z-index: -1


			@media screen and (max-width: $phone)
				top: -10rem
				max-width: 99%


		&--table


			&--1
				object-fit: contain
				object-position: left

				position: absolute
				
				top: 74rem
				left: 0
				
				height: 93rem

				max-height: 95vh
				max-width: 30%

				z-index: -1


				@media screen and (max-width: $phone)
					top: 73rem
					height: 33rem
					max-width: 93%


			&--2
				object-fit: contain
				object-position: right

				position: absolute
				
				top: 115rem
				right: 0
				
				height: 93rem

				max-height: 95vh
				max-width: 60%

				z-index: -1


				@media screen and (max-width: $phone)
					top: 38rem
					height: 28rem
					max-width: 55%


	&__content
		position: absolute

		top: 29vh


		@media screen and (max-width: $desktop)
			top: 25vh


		@media screen and (max-width: $phone)
			width: 89%
			top: 29rem

			text-align: center


	&__title
		width: 57.9rem
		margin-bottom: 1.8rem


		@media screen and (max-width: $phone)
			width: 99%


	&__sub-title


		@media screen and (max-width: $desktop)
			max-width: 32.9rem


		@media screen and (max-width: $phone)
			width: 100%


	&__table
		
		
		&-row
			padding: 6rem 0
			align-items: stretch


			@media screen and (max-width: $phone)
				display: flex
				flex-direction: column
				align-items: flex-start
				justify-content: space-between


			&:not(:last-child)
				border-bottom: 1px solid rgba($color_black_light, .16)


		&-number
			flex-basis: 10%
			font-size: $article_font_size


			@media screen and (max-width: $phone)
				display: none


		&-name
			
			
			&-box
				flex-basis: 30%
				align-items: flex-start

				position: relative

				top: -1rem


		&-description
			flex-basis: 60%


		&-links
			margin-top: 3.7rem


			@media screen and (max-width: $phone)
				display: flex
				flex-direction: column
				align-items: flex-start
				justify-content: space-between


		&-link


			@media screen and (max-width: $phone)
				font-size: $default_font_size__mobile


			&:not(:last-child)
				margin-right: 4.7rem

			
				@media screen and (max-width: $phone)
					margin-bottom: 3rem


</style>